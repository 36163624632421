import {Component, OnInit} from '@angular/core';
import {AnalyticsService} from './@core/utils/analytics.service';
import {SeoService} from './@core/utils/seo.service';
import {SharedUserService} from "../services/shared-user.service";
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'ngx-app',
  template: '<router-outlet></router-outlet>',
  providers: [SharedUserService, AuthService]
})
export class AppComponent implements OnInit {

  constructor(
    /*private analytics: AnalyticsService,
    private seoService: SeoService,
    private userService: SharedUserService*/
    private authService: AuthService
  ) {
  }

  ngOnInit(): void {
    // this.analytics.trackPageViews();
    // this.seoService.trackCanonicalChanges();
    
    setInterval(() => {
      if(localStorage.getItem("auth_app_token")) this.authService.sendPingToServer().subscribe();
    },60000)

  }
}
