import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {Auth} from '../models/auth.model';
import {environment} from '../environments/environment';
import {map} from 'rxjs/operators';
import {ApiResponse} from "../models/api_response.entity";
import {
    getDeepFromObject,
    NB_AUTH_OPTIONS,
    NbAuthResult,
    NbAuthService,
    NbAuthToken,
    NbTokenService
} from "@nebular/auth";

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private readonly apiUrl = environment.wsport > 0 ? environment.wsurl + ':' + environment.wsport : environment.wsurl;

    header;
    redirectDelay: number = 0;
    strategy: string = '';
    private isLoggedInSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isLoggedIn$: Observable<boolean> = this.isLoggedInSubject.asObservable();
    token: NbAuthToken;

    constructor(
        private http: HttpClient,
        private router: Router,
        private nbAuthService: NbAuthService,
        protected tokenService: NbTokenService,
        @Inject(NB_AUTH_OPTIONS) protected options = {}
    ) {

        this.redirectDelay = this.getConfigValue('forms.logout.redirectDelay');
        this.strategy = 'username';
        this.nbAuthService.onTokenChange()
            .subscribe((token: NbAuthToken) => {
                this.token = null;
                if (token && token.isValid()) {
                    this.token = token;
                }
            });
    }

    getConfigValue(key: string): any {
        return getDeepFromObject(this.options, key, null);
    }

    saveTheme(id: number, theme: any): any {
        const url = `${this.apiUrl}/auth/theme`;
        return this.http.post<ApiResponse>(url, {headers: this.header, id, theme}).subscribe(res => {
            return res;
        });
    }

    /**
     * envoyer une requete sécurisée
     */
    getProtectedData() {
        const token = localStorage.getItem('access_token');
        if (token) {
            const headers = {'Authorization': `Bearer ${token}`};
            return this.http.get<any>(`${this.apiUrl}/`, {headers}).toPromise();
        } else {
            return Promise.reject('Token is invalid or expired');
        }
    }


    /**
     * Service d'Authentification
     * @param username
     * @param password
     */
    public login(username: string, password: string) {
        return this.http.post<any>(`${this.apiUrl}/auth/login`, {username: username, password: password})
            .pipe(
                map(response => {
                    if (response && response.status === 'OK') {
                        localStorage.setItem('access_token', response.data.access_token);
                        localStorage.setItem('user', JSON.stringify(response.data.client));
                        localStorage.setItem('role', response.data.role);
                        this.isLoggedInSubject.next(true);
                        return true;
                    } else {
                        this.tokenService.clear();
                        this.logout();
                        this.isLoggedInSubject.next(false);
                        return response;
                    }
                })
            );
    }

    /**
     * Fonction pour envoyer chaque minute un ping au serveur
     */
    public sendPingToServer(): Observable<any> {
        const clientId: number = Number(localStorage.getItem('clientId'));
        const token = localStorage.getItem('access_token');
        const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
        if (clientId !== undefined) {
            return this.http.put<any>(`${this.apiUrl}/auth/last-ping`, {id: clientId}, {headers: headers});
        }
    }


    find(id: number): Observable<ApiResponse> {
        const url = `${this.apiUrl}/auth/${id}`;
        return this.http.get<ApiResponse>(url, {headers: this.header});
    }

    addAuth(auth: Auth): Observable<Auth> {
        return this.http.post<Auth>(this.apiUrl + '/auth', auth, {headers: this.header});
    }

    resetPass(email: string): Observable<ApiResponse> {
        return this.http.post<ApiResponse>(this.apiUrl + '/auth/forgotten', {email: email});
    }

    updateAuth(id: number, auth: Auth): Observable<Auth> {
        const url = `${this.apiUrl}/auth/${id}`;
        return this.http.put<Auth>(url, auth, {headers: this.header});
    }

    save(auth: Auth): Observable<ApiResponse> {
        if (auth.id) {
            const url = `${this.apiUrl}/auth/save/${auth.id}`;
            return this.http.put<ApiResponse>(url, auth, {headers: this.header});
        } else {
            const response = new ApiResponse();
            response.data = this.addAuth(auth);
            response.status = 'SUCCESS';
            response.code = 200;
            return of(response);
        }
    }

    deconsteAuth(id: number): Observable<Auth> {
        const url = `${this.apiUrl}/auth/${id}`;
        return this.http.delete<Auth>(url, {headers: this.header});
    }

    /**
     * Get auth token depios local storage
     */
    public getToken(): any {
        const accessToken = localStorage.getItem('access_token') ?? '';
        const user = localStorage.getItem('user') ?? '';
        const role = localStorage.getItem('role' ?? null);
        return {
            accessToken: accessToken,
            user: user,
            role: role
        };
    }

    public getUserInfo(): any {
        const token = this.getToken();
        if (token && token.accessToken) {
            try {
                return localStorage.getItem('clientId');
            } catch (error) {
                console.error(error);
                console.log('Erreur lors de la récupération des informations utilisateur. Redirection vers la page de connexion.');
                this.router.navigate(['/auth/login']);
            }
        }
        return null;
    }

    public logout(): void {
        const idCLient: number = Number(localStorage.getItem('clientId'));
        localStorage.clear();
        this.isLoggedInSubject.next(false);
        this.nbAuthService.logout('username')
            .subscribe((result: NbAuthResult) => {
                this.http.put<any>(`${this.apiUrl}/auth/clear-last-ping`, {id: idCLient}).subscribe((res) => {
                    this.router.navigate(['/auth/login']);
                });

            });

    }

    public removeAuth(id): Observable<ApiResponse> {
        return this.http.delete<ApiResponse>(`${this.apiUrl}/auth/${id}`);
    }

    /**
     * Déconnexion de l'admin
     */
    adminLogout(): void {
        // on efface le local storage
        localStorage.clear();
        this.isLoggedInSubject.next(false);
        this.nbAuthService.logout('username')
            .subscribe((result: NbAuthResult) => {
                this.router.navigate(['/auth/login']);
            });
    }

    isLoggedIn(): boolean {
        const accessToken = localStorage.getItem('access_token');
        if (accessToken !== null && accessToken !== '') {
            return true;
        }
        this.logout();
        return false;
    }

    public isTokenExpired(token: string): boolean {
        return true;
    }

    redirectAfterTokenExpired(tokenExpired: boolean) {
        console.log('tokenExpired: ', tokenExpired);
        if (tokenExpired) {
            const user = this.getUserInfo();
            if (user.type === 1) {
                // console.log('type admin: appel de la fonction adminLogout');
                this.adminLogout();
            } else {
                // console.log('type autre que admin donc appel de la fonction logout');
                this.logout();
            }
        }
    }

    /**
     * Prend la liste des authentifications par clientId
     * @param id
     */
    getAuthsByClient(id: number): Observable<ApiResponse> {
        const token = localStorage.getItem('auth_app_token');
        if (token) {
            const headers = {'Authorization': `Bearer ${token}`};
            return this.http.post<ApiResponse>(`${this.apiUrl}/auth/by-client`, {clientId: id});
        } else {
            this.logout();
        }
    }

}
