// {
//     "fontMain": "Open Sans, sans-serif",
//     "fontSecondary": "Raleway, sans-serif",
//     "bg": "#323259",
//     "bg2": "#252547",
//     "bg3": "#1b1b38",
//     "bg4": "#13132b",
//     "border": "#323259",
//     "border2": "#252547",
//     "border3": "#1b1b38",
//     "border4": "#13132b",
//     "border5": "#13132b",
//     "fg": "#b4b4db",
//     "fgHeading": "#ffffff",
//     "fgText": "#ffffff",
//     "fgHighlight": "#a16eff",
//     "layoutBg": "#151a30",
//     "separator": "#151a30",
//     "primary": "#a16eff",
//     "success": "#00d68f",
//     "info": "#0095ff",
//     "warning": "#ffaa00",
//     "danger": "#ff3d71",
//     "primaryLight": "#b18aff",
//     "successLight": "#2ce69b",
//     "infoLight": "#42aaff",
//     "warningLight": "#ffc94d",
//     "dangerLight": "#ff708d"
// }
export const CUSTOM_THEME = {
  t0_barreHaute: {
    background: "#FFFFFF"
  },
  t1_menuGauche: {
    background: "#FFFFFF"
  },
  t2_card: {
    vert: "#90a4ae",
    rouge: "#e57373",
    background: "#FFFFFF",
    textActive: "#2196f3",
    headerTextActive: "#2196f3"
  },
  t3_buttons: {
    cancelText: "#FFFFFF",
    submitText: "#FFFFFF",
    backgroundCancel: "#64b5f6",
    backgroundSubmit: "#e57373"
  },
  t4_textes: {
    texte: "#000000",
    titre: "#4fc3f7"
  },
  t5_graph: {
    "g1": "#ffab91",
    "g2": "#00d68f",
    "g3": "#0095ff",
    "g4": "#ffaa00",
    "g5": "#ff3d71",
    "g6": "#b18aff",
    "g7": "#2ce69b",
    "g8": "#42aaff",
    "g9": "#ffc94d",
    "g10": "#ff708d",
    "g11": "#323259",
    "g12": "#80deea",
    "g13": "#ffe082",
    "g14": "#81c784",
    "g15": "#13132b",
    "g16": "#b4b4db"
  }
};
