
<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="/" (click)="navigateHome()">
      <img src="{{ logo }}" style="width:75px;" title="{{ raisonSociale }}" alt="Logo {{ raisonSociale }}"/>
    </a>
  </div>

</div>

<div class="header-container">
  <nb-actions size="small" *ngIf="!isMobile">
    <nb-action class="control-item" icon="bell-outline" link="/gestion/notifications" *ngIf="user && user.isAdmin"></nb-action>
    <nb-action class="user-action">
      <nb-user [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [name]="user?.name"
               [picture]="user?.picture">
      </nb-user>
    </nb-action>
    <nb-action (click)="fonction()" class="control-item" icon="power-outline" link="/auth/logout"></nb-action>
  </nb-actions>
</div>
