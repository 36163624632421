import {Auth} from "../models/auth.model";
import {Observable, Observer} from "rxjs";

export class SharedUserService {
  globalVar: Auth;
  globalVarUpdate: Observable<Auth>;
  globalVarObserver: Observer<Auth>;

  constructor() {
    this.globalVarUpdate = Observable.create((observer: Observer<Auth>) => {
      this.globalVarObserver = observer;
    });
  }

  updateGlobalVar(newValue: Auth) {
    this.globalVar = newValue;
    if (this.globalVarObserver)
      this.globalVarObserver.next(this.globalVar);
  }
}
