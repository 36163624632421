import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService} from '@nebular/theme';
import {LayoutService} from '../../../@core/utils';
import {map, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {SharedUserService} from "../../../../services/shared-user.service";
import {ClientService} from "../../../../services/client.service";
import {AuthService} from "../../../../services/auth.service";
import {AuthTypes} from "../../../../constants/AuthTypes.enum";
import {NbAuthService, NbTokenService} from "@nebular/auth";
import {CUSTOM_THEME} from "../../styles/theme.custom";
import {Platform} from "@angular/cdk/platform";

@Component({
    selector: 'ngx-header',
    styleUrls: ['./header.component.scss'],
    templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
    isMobile: boolean = false;
    screenHeight;
    screenWidth;

    private destroy$: Subject<void> = new Subject<void>();
    userPictureOnly: boolean = false;
    user: any = {name: '', picture: '', isAdmin: false};
    themeStyles: any;

    themes = [
        {
            value: 'corporate',
            name: 'Clair',
        },
        {
            value: 'dark',
            name: 'Sombre',
        },
    ];

    currentTheme = 'corporate';

    userMenu = [{title: 'Déconnexion', link: '/auth/logout'}];

    logo: string = '';
    raisonSociale: string = '';

    constructor(private sidebarService: NbSidebarService,
                private menuService: NbMenuService,
                private themeService: NbThemeService,
                private clientService: ClientService,
                private authService: AuthService,
                private layoutService: LayoutService,
                private breakpointService: NbMediaBreakpointsService,
                private sharedUserService: SharedUserService,
                private nbAuthService: NbAuthService,
                protected tokenService: NbTokenService,
                private platform: Platform,
    ) {
        this.screenWidth = window.innerWidth;
        this.screenHeight = window.innerHeight;
        this.isMobile = this.platform.IOS || this.platform.ANDROID || this.screenWidth <= 991;
        this.themeStyles = localStorage.getItem('theme');
    }

    @HostListener('window:resize', ['$event'])
    onResize(event?) {
        this.screenHeight = event ? event.target.innerHeight : window.innerHeight;
        this.screenWidth = event ? event.target.innerWidth : window.innerWidth;
        const simulMobile = this.screenWidth <= 991;
        this.isMobile = this.platform.IOS || this.platform.ANDROID || simulMobile;
    }

    ngOnInit() {
        this.nbAuthService.getToken().subscribe(
            (token) => {
                this.authService.find(token.getPayload().authId).subscribe(
                    response => {
                        if (response.status === 'OK') {
                            const auth = response.data;
                            if (auth.theme === null || auth.theme === undefined || auth.theme === "undefined") {
                                auth.theme = CUSTOM_THEME;
                            }
                            this.themeStyles = auth.theme;
                            localStorage.setItem('theme', JSON.stringify(auth.theme));
                            localStorage.setItem('logo', auth.client.logo);
                            localStorage.setItem('raisonSociale', auth.client.raisonSociale);
                            localStorage.setItem('nom', auth.nom);
                            localStorage.setItem('prenoms', auth.prenoms);
                            localStorage.setItem('authTypeId', auth.authTypeId + ' ');
                            localStorage.setItem('clientId', auth.client.id + ' ');
                            if (localStorage.getItem('auth_app_token')) localStorage.setItem('access_token', JSON.stringify(JSON.parse(localStorage.getItem('auth_app_token'))));
                            this.sharedUserService.updateGlobalVar(auth);
                            this.logo = localStorage.getItem('logo');
                            this.user.isAdmin = Number(localStorage.getItem('authTypeId')) === AuthTypes.SuperAdministrator || Number(localStorage.getItem('authTypeId')) === AuthTypes.Administrator;
                            this.raisonSociale = localStorage.getItem('raisonSociale');
                            this.user.name = localStorage.getItem('nom') + ' ' + localStorage.getItem('prenoms');
                            this.user.picture = this.logo;
                            if (auth.authTypeId > 2) {
                                this.toggleSidebar();
                            }
                        } else {
                            this.tokenService.clear();
                            this.authService.logout();
                        }
                    }
                );
            }
        );
        // this.currentTheme = this.themeService.currentTheme;

        this.changeTheme(this.currentTheme);
        const {xl} = this.breakpointService.getBreakpointsMap();
        this.themeService.onMediaQueryChange()
            .pipe(
                map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
                takeUntil(this.destroy$),
            )
            .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

        this.themeService.onThemeChange()
            .pipe(
                map(({name}) => name),
                takeUntil(this.destroy$),
            )
            .subscribe(themeName => this.currentTheme = themeName);
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    changeTheme(themeName: string) {
        this.themeService.changeTheme(themeName);
    }

    toggleSidebar(): boolean {
        this.sidebarService.toggle(true, 'menu-sidebar');
        this.layoutService.changeLayoutSize();

        return false;
    }

    navigateHome() {
        this.menuService.navigateHome();
        return false;
    }

    fonction() {
        console.log("EOUAH");
    }
}
