import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {NbAuthJWTToken, NbAuthService, NbAuthToken} from '@nebular/auth';
import {NbRoleProvider} from '@nebular/security';
import {AuthTypes} from "../../constants/AuthTypes.enum";

@Injectable()
export class DacqsysRoleProvider implements NbRoleProvider {
  constructor(private authService: NbAuthService) {
  }

  getRole(): Observable<string> {
    return this.authService.onTokenChange().pipe(
      map((token: NbAuthToken) => {
        let role = 'guest';
        if (token instanceof NbAuthJWTToken && token.isValid()) {
          switch (token.getPayload().type) {
            case AuthTypes.Administrator:
              role = 'administrator';
              break;
            case AuthTypes.Observator:
              role = 'observator';
              break;
            case AuthTypes.Supervisor:
              role = 'supervisor';
              break;
          }
        }
        return role;
      }),
    );
  }
}
