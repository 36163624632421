<nb-layout windowMode ngxCustomTheme>
  <nb-layout-header fixed>
    <ngx-header></ngx-header>
  </nb-layout-header>

  <nb-sidebar data-nb-column="1" class="menu-sidebar" tag="menu-sidebar" responsive>
    <ng-content select="nb-menu"></ng-content>
  </nb-sidebar>

  <nb-layout-column>
    <ng-content select="router-outlet"></ng-content>
  </nb-layout-column>
</nb-layout>
