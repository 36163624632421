import {Component, HostBinding, HostListener, OnInit} from '@angular/core';
import {NbAuthService} from "@nebular/auth";
import {SharedUserService} from "../../../../services/shared-user.service";
import {AuthService} from "../../../../services/auth.service";
import {CUSTOM_THEME} from "../../styles/theme.custom";
import {ThemingService} from "../../../../services/theming.service";
import {Subscription} from "rxjs";
import {Platform} from "@angular/cdk/platform";

@Component({
    selector: 'ngx-one-column-layout',
    styleUrls: ['./one-column.layout.scss'],
    templateUrl: './one-column.layout.html',
})
export class OneColumnLayoutComponent implements OnInit {
    isMobile: boolean = false;
    screenHeight;
    screenWidth;
    // header
    @HostBinding("style.--header-background-color") headerBackgroundColor: string = '';
    @HostBinding("style.--header-text-color") headerTextColor: string = '';

    // sidebar
    @HostBinding("style.--menu-item-active-text-color") menuItemActiveTextColor: string = '';
    @HostBinding("style.--menu-text-color") menuItemTextColor: string = '';
    @HostBinding("style.--menu-item-icon-active-color") menuItemIconActiveColor: string = '';
    @HostBinding("style.--sidebar-background-color") sidebarBackgroundColor: string = '';
    // card
    @HostBinding("style.--card-background-color") cardBackgroundColor: string = '';
    @HostBinding("style.--card-header-text-color") tabsetTabHoverTextColor: string = '';
    @HostBinding("style.--list-item-text-color") listItemTextColor: string = '';
    @HostBinding("style.--smart-table-fg") smartTableFg: string = '';
    @HostBinding("style.--tabset-tab-active-text-color") tabsetTabHoverTextAtiveColor: string = '';
    @HostBinding("style.--tabset-tab-active-underline-color") tabsetTabHoverUnderlineTextAtiveColor: string = '';
    @HostBinding("style.--text-basic-color") textBasicColor: string = '';
    @HostBinding("style.--button-hero-success-left-background-color") buttonHeroSuccessLeftBackgroundColor: string = '';
    @HostBinding("style.--badge-success-background-color") badgeSuccessBackgroundColor: string = '';
    @HostBinding("style.--badge-danger-background-color") badgeDangerBackgroundColor: string = '';
    // boutons
    @HostBinding("style.--button-filled-danger-background-color") buttonSubmit: string = '';
    @HostBinding("style.--button-filled-danger-border-color") buttonSubmitBorder: string = '';
    @HostBinding("style.--button-filled-primary-background-color") buttonCancel: string = '';
    @HostBinding("style.--button-filled-primary-background-color") buttonCancelBorder: string = '';
    @HostBinding("style.--button-filled-danger-text-color") buttonSubmitText: string = '';
    @HostBinding("style.--button-filled-primary-text-color") buttonCancelText: string = '';
    authTypeId: number = 4;
    auth;

    themeUpdated: any;
    private themServiceSubscription: Subscription;

    constructor(
        private nbAuthService: NbAuthService,
        private authService: AuthService,
        private sharedUserService: SharedUserService,
        private customTheming: ThemingService,
        private platform: Platform,
    ) {
        const simulMobile = this.screenWidth <= 991;
        this.isMobile = this.platform.IOS || this.platform.ANDROID || simulMobile;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event?) {
        this.screenHeight = event ? event.target.innerHeight : window.innerHeight;
        this.screenWidth = event ? event.target.innerWidth : window.innerWidth;
        const simulMobile = this.screenWidth <= 991;
        this.isMobile = this.platform.IOS || this.platform.ANDROID || simulMobile;
    }

    ngOnInit() {
        this.themServiceSubscription = this.customTheming.observeActiveThemeChange()
            .subscribe(theme => {
                this.themeUpdated = theme;
                this.updateTheme();
            });
        this.nbAuthService.getToken().subscribe(
            (token) => {
                this.authService.find(token.getPayload().authId).subscribe(
                    response => {
                        if (response.status === 'OK') {
                            this.auth = response.data;
                            if (this.auth.theme === null || this.auth.theme === undefined || this.auth.theme === "undefined") {
                                this.auth.theme = CUSTOM_THEME;
                            }
                            this.themeUpdated = this.auth.theme;
                            this.updateTheme();
                            this.customTheming.updateTheme(this.themeUpdated);
                            localStorage.setItem('logo', this.auth.client.logo);
                            localStorage.setItem('raisonSociale', this.auth.client.raisonSociale);
                            localStorage.setItem('nom', this.auth.nom);
                            localStorage.setItem('prenoms', this.auth.prenoms);
                            localStorage.setItem('authTypeId', this.auth.authTypeId + ' ');
                            localStorage.setItem('clientId', this.auth.client.id + ' ');
                            this.sharedUserService.updateGlobalVar(this.auth);
                            // this.globalAuth.set(auth);
                            this.authTypeId = this.auth.authTypeId;
                        } else {
                            localStorage.clear();
                            this.authService.logout();
                        }
                    }
                );
            }
        );
    }

    checkExistence(parent, fils) {
        if (this.themeUpdated && this.themeUpdated[parent] && this.themeUpdated[parent][fils]) {
            return this.themeUpdated[parent][fils];
        } else {
            return CUSTOM_THEME[parent][fils];
        }
    }

    updateTheme() {
        // header
        this.headerBackgroundColor = this.checkExistence('t0_barreHaute', 'background'); //
        // this.headerTextColor = this.themeUpdated.t0_barreHaute.text;
        // left menu
        this.menuItemActiveTextColor = this.checkExistence('t4_textes', 'titre');
        this.menuItemIconActiveColor = this.checkExistence('t4_textes', 'titre');
        this.menuItemTextColor = this.checkExistence('t4_textes', 'texte');
        this.sidebarBackgroundColor = this.checkExistence('t1_menuGauche', 'background');
        // card
        this.cardBackgroundColor = this.checkExistence('t2_card', 'background');
        this.tabsetTabHoverTextColor = this.checkExistence('t4_textes', 'texte');
        this.listItemTextColor = this.checkExistence('t4_textes', 'texte');
        this.smartTableFg = this.checkExistence('t4_textes', 'texte');
        this.tabsetTabHoverTextAtiveColor = this.checkExistence('t2_card', 'headerTextActive');
        this.tabsetTabHoverUnderlineTextAtiveColor = this.checkExistence('t2_card', 'headerTextActive');
        this.textBasicColor = this.checkExistence('t4_textes', 'texte');
        // badges
        this.buttonHeroSuccessLeftBackgroundColor = this.checkExistence('t2_card', 'vert');
        this.badgeSuccessBackgroundColor = this.checkExistence('t2_card', 'vert');
        this.badgeDangerBackgroundColor = this.checkExistence('t2_card', 'rouge');
        // buttons
        this.buttonSubmit = this.checkExistence('t3_buttons', 'backgroundSubmit');
        this.buttonSubmitBorder = this.checkExistence('t3_buttons', 'backgroundSubmit');
        this.buttonCancel = this.checkExistence('t3_buttons', 'backgroundCancel');
        this.buttonCancelBorder = this.checkExistence('t3_buttons', 'backgroundCancel');
        this.buttonCancelText = this.checkExistence('t3_buttons', 'cancelText');
        this.buttonSubmitText = this.checkExistence('t3_buttons', 'submitText');
    }

}
