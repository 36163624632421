import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Client} from '../models/client.model';
import {Observable} from 'rxjs';
import {delay, map} from 'rxjs/operators';
import {CreateDeviceDto} from "../models/device.model";
import {environment} from "../environments/environment";
import {DeviceType} from "../models/device_type.model";
import {ApiResponse} from "../models/api_response.entity";

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  private deviceUrl = environment.wsport > 0 ? environment.wsurl + ':' + environment.wsport + '/devices' : environment.wsurl + '/devices';
  private apiUrl = environment.wsport > 0 ? environment.wsurl + ':' + environment.wsport + '/clients' : environment.wsurl + '/clients';
  private deviceTypesUrl = environment.wsport > 0 ? environment.wsurl + ':' + environment.wsport + '/device-types' : environment.wsurl + '/device-types';
  token: string;
  header;

  clientDevices: Observable<ApiResponse>;
  deviceTypes: Observable<DeviceType[]>;

  constructor(private http: HttpClient) {
    this.token = localStorage.getItem("access_token");
    if (this.token === null) {
      this.token = localStorage.getItem("auth_app_token");
      localStorage.setItem("access_token", this.token);
    }
    this.header = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
  }

  // Clear caches
  clearCache() {
    this.deviceTypes = null;
    this.clientDevices = null;
  }

  /**
   * Prends la liste de types de dispositifs depuis l'pi
   */
  getDeviceTypes(): Observable<DeviceType[]> {
    return this.http.get<DeviceType[]>(this.deviceTypesUrl, {headers: this.header});
  }

  /**
   * Prends la liste dees clients depuis l'pi
   */
  getClients(): Observable<Client[]> {
    return this.http.get<Client[]>(this.apiUrl, {headers: this.header});
  }

  getClient(id: number): Observable<Client> {
    return this.http.get<Client>(`${this.apiUrl}/${id}`, {headers: this.header});
  }

  addClient(client: Client): Observable<Client> {
    return this.http.post<Client>(this.apiUrl, client, {headers: this.header});
  }

  updateClient(id: number, client: Client): Observable<Client> {
    const url = `${this.apiUrl}/${id}`;
    return this.http.put<Client>(url, client, {headers: this.header});
  }

  deleteClient(id: number): Observable<Client> {
    const url = `${this.apiUrl}/${id}`;
    return this.http.delete<Client>(url, {headers: this.header});
  }

  getClientDevices(clientId: number, isSuperadmin: boolean = false): Observable<ApiResponse> {
    let url = `${this.deviceUrl}/client-objects/${clientId}`;
    if (isSuperadmin || clientId === 1) {
      url = this.deviceUrl;
    }
    return this.http.get<ApiResponse>(url, {headers: this.header});
  }

  load(clientId: number, page: number, pageSize: number = 5): Observable<ApiResponse> {
    const startIndex = ((page - 1) % 1) * pageSize;
    const url = `${this.deviceUrl}/paginate/${clientId}/${page}/${pageSize}`;
    return this.http.get<ApiResponse>(url, {headers: this.header})
      .pipe(
        map(devices => devices.data),
        delay(1500),
      );
  }

  getAllObjectsForClient(clientId: number): Observable<ApiResponse> {
    const url = `${this.deviceUrl}/list-objects-client/${clientId}`;
    return this.http.get<ApiResponse>(url, {headers: this.header});
  }

  createDevice(createDeviceDto: CreateDeviceDto): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(`${this.deviceUrl}`, createDeviceDto, {headers: this.header});
  }

  getDevice(deviceId: number): Observable<ApiResponse> {
    const url = `${this.deviceUrl}/${deviceId}`;
    return this.http.get<ApiResponse>(url, {headers: this.header});
  }

  getDeviceByImei(imei: string): Observable<ApiResponse> {
    const url = `${this.deviceUrl}/imei/${imei}`;
    return this.http.get<ApiResponse>(url, {headers: this.header});
  }

  updateDevice(id: number, device: CreateDeviceDto): Observable<ApiResponse> {
    const url = `${this.deviceUrl}/${id}`;
    return this.http.put<ApiResponse>(url, device, {headers: this.header});
  }

  deleteDevice(deviceId: number): Observable<ApiResponse> {
    const url = `${this.deviceUrl}/${deviceId}`;
    return this.http.delete<ApiResponse>(url, {headers: this.header});
  }
}
