import {Injectable} from '@angular/core';
import {CUSTOM_THEME} from "../app/@theme/styles/theme.custom";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ThemingService {
  theme: any = CUSTOM_THEME;
  eventUpdate = new BehaviorSubject<any>(this.theme);

  constructor() {
    this.theme = localStorage.getItem('theme');
    if (this.theme === null || this.theme === undefined || this.theme === "undefined") {
      this.theme = CUSTOM_THEME;
    } else {
      this.theme = JSON.parse(this.theme);
    }
  }

  updateTheme(data: any) {
    this.theme = data;
    localStorage.setItem('theme', JSON.stringify(data));
  }

  public observeActiveThemeChange() {
    return this.eventUpdate.asObservable();
  }

  public notifyActiveThemeChange(name) {
    this.eventUpdate.next(name);
  }

  getTheme() {
    this.theme = JSON.parse(localStorage.getItem('theme'));
    return this.theme;
  }

  convetToRgb(color: string) {
    if (color.search("rgb") !== -1) {
      return this.rbgFullToHex(color);
    } else {
      return color;
    }
  }

  componentToHex = (c) => {
    const hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  }

  rgbToHex = (r, g, b) => {
    return "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
  }

  rbgFullToHex = (str) => {
    const rgb = str.replace('rgb(', '').replace(')', '').replace(' ', '').split(',');
    if (rgb.length === 3) {
      return this.rgbToHex(rgb[0], rgb[1], rgb[2]);
    } else {
      return str;
    }
  }
}
