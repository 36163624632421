import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from '@nebular/auth';
import {ClientAuthGuard} from "../guards/client-auth.guard";

export const routes: Routes = [
  {
    path: 'gestion',
    loadChildren: () => import('./gestion/gestion.module')
      .then(m => m.GestionModule),
    canActivate: [ClientAuthGuard]
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module')
      .then(m => m.AuthModule),
  },
  {path: '', redirectTo: 'gestion', pathMatch: 'full'},
  {path: 'authentification', redirectTo: 'auth/login', pathMatch: 'full'},
  {path: 'deconnexion', redirectTo: 'auth/logout', pathMatch: 'full'},
  {path: '**', redirectTo: 'gestion'},
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
