import {Injectable} from '@angular/core';
import {tap} from 'rxjs/operators';
import {CanActivate, Router} from '@angular/router';
import {NbAuthService} from '@nebular/auth';

@Injectable()
export class ClientAuthGuard implements CanActivate {

    constructor(private authService: NbAuthService, private router: Router) {
    }

    canActivate() {
        this.authService.isAuthenticatedOrRefresh()
            .pipe(
                tap(authenticated => {
                    this.authService.getToken().subscribe(
                        (token) => {
                            console.log(token.getPayload().getTokenExpDate());
                        });
                    if (!authenticated) {
                        this.router.navigate(['auth/login']);
                        localStorage.setItem('devices', '');
                        localStorage.setItem('widgets', '');
                    } else {
                        localStorage.setItem('devices', '');
                    }
                }),
            );
        return this.authService.isAuthenticated()
            .pipe(
                tap(authenticated => {
                    if (!authenticated) {
                        localStorage.clear();
                        this.router.navigate(['auth/login']);
                    } else {
                        this.authService.getToken().subscribe(
                            (token) => {
                                if (!token.isValid()) {
                                    localStorage.setItem('devices', '');
                                    localStorage.setItem('widgets', '');
                                    localStorage.setItem('hasChambreFroide', 'false');
                                    this.router.navigate(['auth/login']);
                                }
                                localStorage.setItem('hasChambreFroide', token.getPayload().hasChambreFroide);
                            });
                    }
                }),
            );
    }
}
