import {Directive, ElementRef, Inject, OnDestroy, OnInit} from '@angular/core';
import {DOCUMENT} from "@angular/common";
import {Subscription} from "rxjs";
import {ThemingService} from "../../../services/theming.service";

@Directive({
  selector: '[ngxCustomTheme]'
})
export class CustomThemeDirective implements OnInit, OnDestroy {

  private theme;
  private themServiceSubscription: Subscription;

  constructor(private elementRef: ElementRef,
              @Inject(DOCUMENT) private document: any,
              private themService: ThemingService) {
  }

  ngOnInit() {
    this.themServiceSubscription = this.themService.observeActiveThemeChange()
      .subscribe(theme => {
        this.theme = theme;
        this.updateTheme(this.theme);
      });
  }

  updateTheme(theme) {
    const element = this.elementRef.nativeElement;
    for (const key in theme) {
      if (theme[key]) {
        element.style.setProperty(key, theme[key]);
        this.document.body.style.setProperty(key, theme[key]);
      }
    }
  }

  ngOnDestroy() {
    if (this.themServiceSubscription) this.themServiceSubscription.unsubscribe();
  }

}
