export class ApiResponse {
    status: string = 'KO';
    code: number = 500;
    message: string = 'Un problème est survenu';
    data: any = null;

    success(data: any, message: string = ''): ApiResponse {
        this.status = 'OK';
        this.code = 200;
        this.message = message;
        this.data = data;
        return this;
    }
}